$color-grey: #abb7bf;


.react-datepicker-wrapper {
  width: 95% !important;
  background: white !important;
}



.datePickerQuotes {
  border-color: lightgray !important;
  border: 1px solid;
  border-radius: 4px;
  padding-top: 10px !important;
  background: white !important; 
}

.datePickerQuotes:focus {
  border: 2px solid;
  border-color: #4a9876 !important;
}

.datePickerQuotes label:focus {
  color: "red";
}

.datePickerQuotes-err {
  border-color: #d32f2f !important;
  color: "#d32f2f" !important;  
  border: 1px solid;
  border-radius: 4px;
  padding-top: 10px !important;
  background: white !important; 
  width: 95% !important;
}

.datePickerQuotes-err::placeholder {
  color: #d32f2f !important;
}


.formField {
  border-color: lightgray !important;
  border: 1px solid;
  border-radius: 4px;
  width: 90% !important;
  padding: 10px 9px !important;
  font-size: 16px;
  background: white !important; 
}
.formField:focus {
  border: 2px solid;
  border-color: #4a9876 !important;
}
.formField-err {
  border-color: #d32f2f !important;
  color: "#d32f2f" !important;  
  border: 1px solid;
  border-radius: 4px;
  width: 90% !important;
  padding: 10px 9px !important;
  font-size: 16px;
  background: white !important; 
}

.formField-err::placeholder {
  color: #d32f2f !important;
}
.activity {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 0;
  padding-top: 0;
  overflow: hidden;
  // position: fixed;
  @media only screen and (max-width: 768px) {
    padding-left: 5px;
    padding-right: 5px;
  }

  &-status {
    margin-left: 10px;
    background: #ffbc4f;
    color: white;
    padding: 2px 4px;
    border-radius: 2px;
  }

  &__add {
    box-shadow: 0px 0px 4px rgba(13, 13, 13, 0.2);
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 10px;

    .c-input-area {
      height: 220px;
    }

    &::-webkit-scrollbar {
      width: 7px;
      height: 10px !important;
    }
  }

  &__next {
    border-bottom: 1px solid #f3f3f6;
    position: relative;
    box-shadow: 0px 0px 4px rgba(13, 13, 13, 0.2);
    border-radius: 8px;
    padding: 12px;
    margin-top: 1.5px;
  }

  &-time {
    height: 30px;
  }

  &-date {
    border: none;
  }

  &-input {
    width: 100%;
    height: 33px;
    border: 1px solid #c5c5d3;
    box-sizing: border-box;
    border-radius: 7px;
    padding-left: 10px;
    margin-bottom: 6px;
    @media only screen and (max-width: 768px) {
      max-width: 100vw;
    }

    &-1 {
      display: inline-block;
      font-size: 12px;
      @media only screen and (max-width: 768px) {
        max-width: 100vw;
      }
    }
  }
}

.c-input {
  height: 34px;
  width: 385px;
  border: 1px solid #c5c5d3;
  border-radius: 5px;
  padding-left: 10px;
  margin-bottom: 5px;
  @media only screen and (max-width: 768px) {
    max-width: 100vw;
  }

  &:focus {
    border: #4a9876;
  }
}

.c-input-area {
  width: 100%;
  height: 150px;
  border: 1px solid #c5c5d3;
  font-family: inherit;
  box-sizing: border-box;
  border-radius: 7px;
  padding: 2px 0px;
  @media only screen and (max-width: 768px) {
    max-width: 100vw;
  }
}
.c-input-area .jodit-toolbar__box:not(:empty) {
  bottom: 0;
}

.activity-title {
  font-weight: 500;
  font-size: 19px;
  line-height: 22px;
  margin-bottom: 8px;
  margin-top: 4px;
}

.date-picker {
  // width: 30px;
  // height: 20px;
  margin-right: 20px;
  display: inline-block;
}

.time-picker {
  display: inline-block;
  // height: 20px;
  // width: 10px;
}

.c-title {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 4px;
  margin-top: 6px;
}

.c-button {
  width: 168.09px;
  height: 56px;
  border: 2px solid #ececef;
  border-radius: 8px;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 5px;
  margin-right: 20px;
  color: #212121;
  background-color: white;
  outline: none;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    width: 150px;
  }
  @media only screen and (max-width: 370px) {
    width: 140px;
    font-size: 13px;
    margin-right: 2px;
  }
}

.c-dropdown {
  width: 179.29px;
  height: 33px;
  border: 1px solid #c5c5d3;
  box-sizing: border-box;
  border-radius: 7px;
  padding-left: 12px;
  padding-right: 13.5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #8b9ba7;
  margin-bottom: 6px;
  @media only screen and (max-width: 600px) {
    width: 160px;
  }
  @media only screen and (max-width: 370px) {
    width: 140px;
    font-size: 13px;
    margin-right: 2px;
  }
}

.logActivityButton {
  width: 114.18px;
  height: 32.55px;
  padding: 2px 17px;
  border: 1px solid #c5c5d3;
  box-sizing: border-box;
  border-radius: 9px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #4A9876;
  margin-top: 12px;
  float: right;
  background-color: white;
  cursor: pointer;
}

.c-radioButton {
  margin-bottom: 8px;
  display: flex;
  overflow-x: auto;
  padding-bottom: 5px;
}

.active {
  border: 2px solid #4A9876;
}

.activityForm_buttons {
  @media only screen and (max-width: 768px) {
    max-width: 100vw;
    width: 100vw;
    overflow: scroll;
  }
}

.activityFormContainerButton {
  display: flex;
  width: 100%;
  justify-content: space-between;

  &-active {
    color: #212121 !important;
    border-bottom: 2px solid #4A9876 !important;
  }

  &-button {
    display: flex;
    align-items: center;
    height: 40px;
    flex: 1;
    border: none;
    border-radius: 0;
    color: #c5c5d3;
    border-bottom: 2px solid #c5c5d3;
    text-align: center;
    justify-content: center;
    font-size: 14px;
    margin-right: 0px !important;
    font-weight: 600 !important;
    font-size: 19px;
    line-height: 19px;
    margin-bottom: 16px;
    margin-right: 20px;

    background-color: white;
    outline: none;
    cursor: pointer;
    padding-bottom: 5px;

    @media only screen and (max-width: 600px) {
      width: 150px;
    }
    @media only screen and (max-width: 370px) {
      width: 140px;
      font-size: 13px;
      margin-right: 2px;
    }
  }
}
