.lanes-panel {
  padding: 15px;
  border: 1px solid #4a9876;
  border-radius: 8px;
  min-height: 85vh;
  height: auto;
  margin-bottom: 20px;
  box-shadow: 0 0 0 6px rgba(75, 200, 147, 0.15);
  position: relative;
  .select__menu {
    z-index: 10000004 !important;
  }
  .select__control--is-focused {
    border-color: #4a9876 !important;
    box-shadow: 0 0 0 1px #4a9876 !important;
  }
  .pac-target-input {
    padding: 10px 4px !important;
  }
  label {
    color: #586874;
    font-weight: 500;
    font-size: 14px;
  }
  input {
    height: 27px;
    font-size: 15px;
  }
  textarea {
    border: 1px solid #c5c5d3;
    border-radius: 4px;
    padding: 5px;
    width: 95%;
    margin: 5px 0;
  }
  &-close-button {
    position: absolute;
    top: 2%;
    right: 1%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  }
  &-status-select {
    font-weight: normal;
    font-size: 13px;
    line-height: 14px;
    padding: 0px !important;
    height: 19px !important;
    border-radius: 4px !important;
    color: #4a8566;
    background-color: #ecfccf;
    width: auto !important;
    margin-bottom: 5px !important;
    outline: none !important;
    margin-left: 5px;
    border: none !important;
  }
  &-form {
    display: flex;
    width: 100%;
    flex: 1;
    flex-wrap: wrap;
    margin-bottom: 8px;
    label {
      color: #586874;
      font-weight: 500;
      font-size: 14px;
    }
    input {
      height: 27px;
      font-size: 15px;
      border: 1px solid #c5c5d3;
      border-radius: 4px;
      padding: 5px;
      width: 95%;
      margin: 5px 0;
    }
    div {
      // width: 100%;
      // width: 250px;
      margin-right: 10px;
      flex: 1;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &-form-1 {
    display: flex;
    width: 100%;
    flex: 1;
    flex-wrap: wrap;
    margin-bottom: 8px;
    label {
      color: #586874;
      font-weight: 500;
      font-size: 14px;
    }
    input {
      height: 27px;
      font-size: 15px;
      width: 95%;
    }
    &-input {
      margin-right: 20px;
      input {
        height: 27px;
        font-size: 15px;
        width: 95%;
        border: 1px solid #c5c5d3;
        border-radius: 4px;
        padding: 5px;
        width: 95%;
        margin: 5px 0;
      }
    }
    &-div {
      width: 14.45vw;
      margin-right: 10px;
      // width: 250px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  input {
    border: 1px solid #c5c5d3;
    border-radius: 4px;
    padding: 5px;
    width: 95%;
    margin: 5px 0;
    &:hover {
      outline: #4a9876;
    }
    &:focus {
      outline: #4a9876;
      border-color: #4a9876 !important;
    }
  }
  select {
    border: 1px solid #c5c5d3;
    border-radius: 4px;
    padding: 5px;
    width: 95%;
    margin: 5px 0;
    &:hover {
      outline: #4a9876;
    }
    &:focus {
      outline: #4a9876;
      border-color: #4a9876 !important;
    }
  }
  .no-border-in-lanes-panel {
    input {
      border: none !important;
      margin: 2px 0;
    }
    textarea {
      border: none !important;
      margin: 2px 0;
    }
  }
}
