// ThreeDotsMenu.scss
.three-dots-menu {
  position: relative;
  display: inline-block;

  .menu-button {
    background: none;
    border: none;
    padding: 2px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    svg {
      fill: currentColor;
      stroke: currentColor;
    }
  }

  .dropdown-menu {
    position: absolute;
    right: 0;
    margin-top: 8px;
    min-width: 200px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: hidden;
    animation: slideIn 0.2s ease-out;

    .menu-item {
      width: 100%;
      padding: 12px 16px;
      border: none;
      background: none;
      text-align: left;
      cursor: pointer;
      font-size: 14px;
      color: #333;
      transition: background-color 0.2s;

      &:hover {
        background-color: #f5f5f5;
      }

      &:active {
        background-color: #eaeaea;
      }
    }
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
