body {
  margin: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #EDF0F2; */
}
body > iframe {
  pointer-events: none;
}
iframe[id="jsd-widget"] {
  pointer-events: all;
}
h1,
h2 {
  font-family: "Poppins", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
}
input,
select,
textarea {
  outline-color: #4a9876;
  font-family: inherit;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
input::-ms-reveal {
  display: none;
}

a {
  color: rgb(65, 190, 137);
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 7px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(#f1f1f1, 0.8);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(37, 42, 45, 0.2);
  border-radius: 50px;
  /* opacity: 0.5; */
}

.border-error {
  border: 2px solid #f36363 !important;
}

.jodit-react-container {
  height: inherit !important;
}
.custom-autocomplete {
  z-index: 10000002;
}
.pac-container {
  z-index: 10000002 !important;
}
iframe#webpack-dev-server-client-overlay {
  display: none;
}
/* InfoBox.css */

.info-box-check-hassan {
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 200px;
  text-align: left;
}

.info-box-check-hassan p {
  margin: 0;
}

.css-wl45mg-control {
  border: 2px solid #62ca9d !important;
}
.jodit-ui-button_variant_primary {
  background-color: #c0ead8 !important;
  border: none;
  border-radius: 4px;
  outline: none;
  font-weight: 500 !important;
  color: #000 !important;
}
.jodit-ui-button_insert_as_text {
  display: none !important;
}
/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: rgba(37, 42, 45,.8);
} */

.MuiModal-root {
  z-index: 10000001 !important;
}
