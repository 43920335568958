.lanes-view {
  background: white;

  .filters-section {
    margin-bottom: 16px;

    .filters-label {
      font-size: 12px;
      color: #6b7280;
      margin-bottom: 4px;
      cursor: pointer;
    }

    .filters-container {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  .filter-button {
    padding: 2px 10px;
    border-radius: 9999px;
    font-size: 12px;
    font-weight: 500;
    border: 1px solid;
    transition: all 0.2s;

    &.prospect {
      &.active {
        background-color: #fef3c7;
        border-color: #fde68a;
        color: #92400e;
      }
    }

    &.spot-won,
    &.contract-won {
      &.active {
        background-color: #d1fae5;
        border-color: #a7f3d0;
        color: #065f46;
      }
    }

    &.spot-lost,
    &.contract-lost {
      &.active {
        background-color: #fee2e2;
        border-color: #fecaca;
        color: #991b1b;
      }
    }

    &:not(.active) {
      background-color: #f9fafb;
      border-color: #e5e7eb;
      color: #9ca3af;

      &:hover {
        background-color: #f3f4f6;
      }
    }

    .count {
      margin-left: 4px;
      opacity: 0.75;
    }
  }

  .lanes-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .lane-item {
    padding: 10px;
    border: 1px solid #f3f4f6;
    border-radius: 8px;
    transition: background-color 0.2s;
    margin-bottom: 16px;

    &:hover {
      background-color: #f9fafb;
    }

    .lane-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 6px;

      .status-section {
        display: flex;
        align-items: center;
        gap: 6px;

        .status-badge {
          padding: 2px 10px;
          border-radius: 9999px;
          font-size: 12px;
          font-weight: 500;

          &.prospect {
            background-color: #fef3c7;
            color: #92400e;
          }

          &.spot-won,
          &.contract-won {
            background-color: #d1fae5;
            color: #065f46;
          }

          &.spot-lost,
          &.contract-lost {
            background-color: #fee2e2;
            color: #991b1b;
          }
        }

        .equipment-type {
          background-color: #f3f4f6;
          color: #4b5563;
          padding: 2px 10px;
          border-radius: 9999px;
          font-size: 12px;
        }

        .dot {
          color: #6b7280;
        }
      }

      .date {
        font-size: 12px;
        color: #6b7280;
      }
    }

    .lane-content {
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 14px;

      .location {
        min-width: 100px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .arrow-icon {
        width: 12px;
        height: 12px;
        stroke: #9ca3af;
        fill: none;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
      }

      .metrics {
        display: flex;
        align-items: center;
        gap: 6px;
        margin-left: auto;
        color: #4b5563;

        .dot {
          color: #9ca3af;
        }

        .volume {
          white-space: nowrap;
        }
      }
    }
  }

  .add-lane-button {
    width: 100%;
    padding: 8px;
    border: 1px dashed #d1d5db;
    border-radius: 8px;
    color: #6b7280;
    font-size: 14px;
    transition: all 0.2s;

    &:hover {
      color: #374151;
      border-color: #9ca3af;
    }
  }

}

.locationField{
  border-color:lightgray !important;
  border: 1px solid;
  border-radius: 4px !important;
  width: 92% !important;
  height: 35px !important;
  padding: 0px 8px !important;
  font-size: 16px !important;
  background: white !important;
  margin-top: 5px !important;
}

.locationField:focus {
  border: 2px solid;
  border-color: #4a9876 !important;
}

