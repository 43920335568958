// LeadStatus.scss
.lead-status-wrapper {
  padding: 6px 3px;

  .lead-status-container {
    max-width: 300px;
    margin: 0 auto;
  }

  .dropdown-container {
    position: relative;
  }

  .dropdown-trigger {
    width: 100%;
    padding: 8px 12px;
    border: none;
    border-radius: 8px;
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.2s ease;

    &:hover {
      filter: brightness(0.98);
    }
  }

  .dropdown-arrow {
    font-size: 10px;
    margin-left: 8px;
  }

  .dropdown-menu {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 4px;
    animation: dropdownFade 0.2s ease;
  }

  .dropdown-item {
    width: 100%;
    padding: 8px 12px;
    border: none;
    border-radius: 6px;
    background: transparent;
    cursor: pointer;
    text-align: left;
    transition: all 0.2s ease;
    margin-bottom: 5px;

    &:hover {
      filter: brightness(0.98);
    }
  }

  .lead-option {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
  }

  .lead-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    flex-shrink: 0;
  }
}

@keyframes dropdownFade {
  from {
    opacity: 0;
    transform: translateY(-4px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Optional: Add these styles if you want to show preview of all options
.preview-container {
  margin-top: 20px;
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  .preview-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
  }

  .preview-options {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .preview-item {
      padding: 8px 12px;
      border-radius: 8px;
      width: 120px;
    }
  }
}
